const { createSlice } = require('@reduxjs/toolkit');

const settingSlice = createSlice({
    name: 'setting',
    initialState: {
        settings: {},
    },
    reducers: {
        setSettings(state, action) {
            state.settings = action.payload;
        }
    },
});

export const { setSettings } = settingSlice.actions;
export default settingSlice.reducer;
